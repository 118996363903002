* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 20px;
}

* + * {
  margin-top: 12px;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

input[type="text"],
input[type="number"],
select {
  font-size: 20px;
  width: 250px;
  padding: 10px;
  appearance: none;
  border: 1px solid lightgray;
}

.taxs {
  display: flex;
}

.taxs > * + * {
  margin-top: 0;
  margin-left: 20px;
}

.tax {
  min-width: 300px;
}
.tax.disabled {
  opacity: 0.2;
}

.checkbox {
  display: flex;
  gap: 8px;
  align-items: center;
}
